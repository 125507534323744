// @flow
import React from "react";

// UI Dependecies
import Grid from "styled-components-grid";
import { Title, Footer, Description, Heading } from "../components/text";
import InfoBox from "../components/infobox";
import LoginForm from "../components/login/form";
import styled, { keyframes } from "styled-components";
import { bounceInDown, fadeIn } from "react-animations";

// Container Dependencies
import type { MessagesType } from "../components/login/form";
import type { AppStateType } from "../redux/reducers";
import { connect } from "react-redux";
import { withStateHandlers, compose, withHandlers } from "recompose";
import { setFlavour } from "../redux/actions/flavour";
import { withRouter } from "react-router-dom";
import { AppIcon } from "../components/img";
import { flavourWithLanguage } from "../redux/reducers/flavour";
import { callAPI } from "../utils/api";
import { setUser } from "../redux/actions/user";
import DataLoader from "../components/data_loader";
import Space from "../components/spacing";
import LanguageWarning from "../components/language-warning";
import { RaisedContainer } from "../components/container";

const bounceInAnimation = keyframes`${bounceInDown}`;
const IconWrapper = styled.span`
  display: block;
  animation: 1s ${bounceInAnimation};
`;
const AnimatedIcon = ({ src }: { src: string }) => (
  <IconWrapper>
    <AppIcon src={src} />
  </IconWrapper>
);

const appearAnimation = keyframes`${fadeIn}`;
const Container = styled(Grid.Unit)`
  padding: 2em;
  animation: 0.8s ${appearAnimation};
  margin: 0 auto;
`;

type LoginPageProps = {
  username?: string,
  password?: string,
  flavour?: {
    title: string,
    icon: string
  },
  flavours?: [string],
  messages: MessagesType,
  loginMessage?: Array<string>,
  isForgotVoucher?: boolean,
  showFlavoursModal?: boolean,
  showFlavours?: boolean,
  disableSubmitButton: boolean,
  onChangeField: (field: string, value: string) => void,
  onClickForgotVoucher: () => void,
  onSubmit: () => void,
  isSubmittingForm: boolean,
  hideBuyButton: boolean,
  onClickScan: () => void,
  selectedLanguage: string
};
export const LoginPage = (props: LoginPageProps) => {
  const {
    username,
    password,
    messages,
    flavour,
    loginMessage,
    isForgotVoucher,
    onChangeField,
    onClickForgotVoucher,
    disableSubmitButton,
    onSubmit,
    isSubmittingForm,
    hideBuyButton,
    onClickScan,
    selectedLanguage
  } = props;
  return (
    <Grid halign="center">
      <RaisedContainer>
        <Container size={{ desktop: 1, mobile: 1 }}>
          {flavour && (
            <Title align="center">
              <AnimatedIcon src={flavour && flavour.icon} />
            </Title>
          )}
          {flavour && <Title align="center">{flavour.title}</Title>}
          {!flavour && (
            <>
              <Heading align="left">{messages.login}</Heading>
              <Description>{messages.enterEmailAndVoucher}</Description>
              <Space v={40} />
            </>
          )}
          {loginMessage && loginMessage.length > 0 && (
            <InfoBox>{loginMessage.map(m => messages[m]).join("\n")}</InfoBox>
          )}
          {flavour && flavour.languages.indexOf(selectedLanguage) === -1 && (
            <LanguageWarning>{messages.unsupportedLanguage}</LanguageWarning>
          )}
          <LoginForm
            hideBuyButton={hideBuyButton || !flavour}
            isSubmittingForm={isSubmittingForm}
            onSubmit={onSubmit}
            disableSubmitButton={disableSubmitButton}
            username={username}
            password={password}
            onChange={onChangeField}
            isForgotVoucher={isForgotVoucher}
            messages={messages}
            onClickForgotVoucher={onClickForgotVoucher}
            onClickScan={onClickScan}
          />

          {!isForgotVoucher && (
            <React.Fragment>
              <Space v={20} />
              <Footer
                dangerouslySetInnerHTML={{ __html: messages.voucherCodeInfo }}
              />
            </React.Fragment>
          )}
        </Container>
      </RaisedContainer>
    </Grid>
  );
};

/**
 * ======================================================
 * Section below is to enhance the LoginPage component
 * ======================================================
 */
// add state to LoginPage
const LoginPageWithState = compose(
  withStateHandlers(
    (props: LoginPageProps) => ({
      username: props.username || "",
      password: props.password || "",
      isForgotVoucher: false,
      showFlavoursModal: false,
      showFlavours: false,
      disableSubmitButton: !props.username || !props.password,
      isSubmittingForm: false,
      loginMessage: props.loginMessage || []
    }),
    {
      onChangeField: state => (field, value) => {
        const newState = {
          ...state,
          [field]: value
        };
        return {
          ...newState,
          disableSubmitButton: state.isForgotVoucher
            ? !newState.username
            : !newState.username || !newState.password
        };
      },
      onClickForgotVoucher: state => () => ({
        isForgotVoucher: !state.isForgotVoucher
      }),
      setDisableSubmitButton: () => disableSubmitButton => ({
        disableSubmitButton
      }),
      setIsSubmittingForm: () => isSubmittingForm => ({ isSubmittingForm }),
      setLoginMessage: () => loginMessage => ({ loginMessage })
    }
  ),
  withHandlers({
    onClickScan: props => () => {
      props.history.push(`/flavour/${props.flavour.name}/scan`);
    },
    onSubmit: props => values => {
      props.setDisableSubmitButton(true);
      props.setIsSubmittingForm(true);
      if (values.isForgotVoucher) {
        callAPI("/forgot", {
          username: values.username.trim(),
          flavour: props.flavour.name,
          lang: props.selectedLanguage
        }).then(result => {
          props.setLoginMessage([result.message]);
          props.onClickForgotVoucher();
        });
      } else {
        callAPI("/login", {
          ...values,
          username: values.username.trim(),
          password: values.password.trim(),
          flavour: props.flavour ? props.flavour.name : ""
        }).then(result => {
          if (result.error) {
            var messages = [result.error.message];
            if (result.error.code === "USE_NEW_ELEARNING") {
              window.alert(
                props.messages["use-new-elearning"] ||
                  "We have a new site for you. Please click here to go there."
              );
              window.location.href = `https://new.elearning.itheorie.ch/${
                props.selectedLanguage
              }/flavor/${
                props.flavour.name
              }/?voucher=${values.password.trim()}&email=${values.username.trim()}`;
            } else if (result.error.code === "INVALID_VOUCHER") {
              if (
                values.password.indexOf("0") > -1 ||
                values.password.toLowerCase().indexOf("o") > -1
              ) {
                messages.push("voucherInvalidZeroOMessage");
              }
            }
            props.setLoginMessage(messages);
          } else {
            if (result.authenticated) {
              if (!props.flavor) {
                props.setFlavour(result.flavourData);
              }
              props.setUser(result);

              return props.history.push("/dashboard");
            }
          }
          props.setDisableSubmitButton(false);
          props.setIsSubmittingForm(false);
        });
      }
    }
  })
)(LoginPage);

// get the messages and flavour props from redux
const mapStateToProps = (state: AppStateType): any => ({
  messages: state.language.messages,
  selectedLanguage: state.language.selected,
  flavour:
    state.flavour && state.flavour.name
      ? flavourWithLanguage(state.flavour, state.language.selected)
      : null
});
const mapDispatchToProps = (dispatch: any) => ({
  setUser: (user: any) => dispatch(setUser(user)),
  setFlavour: (data: any) => dispatch(setFlavour(data))
});

const LoginPageWithData = props => {
  window.sessionStorage.removeItem("transactionId");
  var propsToPass = props;
  if (props.location.state) {
    // after completing payment, user will be directed back to the login page from the buy page
    // the buy page component will push the route with a state containing the email and the voucher code.
    propsToPass = {
      ...propsToPass,
      username: props.location.state.username,
      password: props.location.state.password,
      loginMessage: props.location.state.loginMessage || "",
      hideBuyButton: true
    };
  }

  var queryString;
  var voucherCode;
  var flavorToUse = props.match.params.flavour;
  if (props.location.search) {
    queryString = props.location.search.substr(1);
    const queryMap = queryString.split("&").map(q => {
      const split = q.split("=");
      const key = split[0];
      const value = split[1];
      return {
        [key]: value
      };
    });

    // The query string could contain flavour that is different than that is in the pathname.
    // e.g, http://localhost:3002/flavour/ch_truck_premium?voucher=5EEQ-A2HO-YSH4&flavor=ch_truck_extended&teacher=807&_branch_match_id=650335163988759938
    // In this case, we prioritize the flavour set in the query string by redirecting it to the login page for that flavour.
    const flavourQuery = queryMap.filter(q => q["flavor"]);
    if (flavourQuery && flavourQuery.length > 0) {
      const flavour = flavourQuery[0]["flavor"];
      if (flavorToUse !== flavour) {
        flavorToUse = flavour;
      }
    }

    const codeQuery = queryMap.filter(q => q["voucher"]);
    if (codeQuery.length > 0) {
      voucherCode = codeQuery[0]["voucher"];
    }
  }

  if (flavorToUse && voucherCode) {
    window.location.href = `https://new.elearning.itheorie.ch/${props.selectedLanguage}/flavor/${flavorToUse}/?voucher=${voucherCode}&email=${propsToPass.username}`;
    return null;
  }

  if (queryString) {
    // if there is voucher={voucher_code} in the url, fill it up in the voucher code field
    const codeQuery = queryString
      .split("&")
      .map(q => {
        const split = q.split("=");
        const key = split[0];
        const value = split[1];
        return {
          [key]: value
        };
      })
      .filter(q => q["voucher"]);
    if (codeQuery.length > 0) {
      const code = codeQuery[0]["voucher"];
      propsToPass = {
        ...propsToPass,
        password: code
      };
    }
  }

  return (
    <DataLoader
      component={LoginPageWithState}
      pathname={props.location.pathname}
      onDataFetch={data => props.setFlavour(data)}
      {...propsToPass}
    />
  );
};

// add location props from react-router
const WithRouter = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPageWithData)
);

export default WithRouter;
