// @flow
import React from "react";

export type BuyPageMessageType = {
  buyVoucherPrice: string,
  pleaseWait: string,
  buyNow: string,
  haveVoucher: string,
  otherProducts: string,
  payOnce: string
};
type BuyPageProps = {
  flavour: {
    name: string,
    icon: string,
    title: string,
    description: string,
    price: string
  },
  info?: string,
  messages: BuyPageMessageType,
  isLoading: boolean,
  onClickBuyButton: () => void,
  onClickBackToLogin: () => void,
  onClickOtherProducts: () => void,
  showOtherProducts: boolean,
  selectedLanguage: string
};
export const BuyPage = (props: BuyPageProps) => {
  const { flavour, selectedLanguage } = props;

  if (!flavour) {
    return null;
  }

  const newSite = `https://new.elearning.itheorie.ch/${selectedLanguage}/flavor/${flavour.name}/buy`;

  return (
    <div style={{ textAlign: "center", fontSize: "1.2em" }}>
      We have a new site for you. Please click <a href={newSite}>here</a> to go
      there.
    </div>
  );
};

BuyPage.defaultProps = {
  isLoading: false,
  onClickBuyButton: () => {}
};
