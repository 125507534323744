// @flow

export default {
  title: "iTheory Online Learning",
  en: "English",
  de: "German",
  it: "Italian",
  fr: "French",
  welcomeTo: "Welcome to $title",
  emailAddress: "E-mail Address",
  cardDetails: "Card Details",
  payWithCreditCard: "Pay with Credit Card",
  login: "Login",
  enterEmailAndVoucher:
    "Enter your email address and the voucher code to start learning.",
  needHelp:
    'Need more help? Contact us at <a href="mailto:support@itheorie.ch">support@itheorie.ch</a>',
  paymentByStripe:
    'The payment is securely processed by <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">Stripe</a>. We do not store your credit card details in our system.',
  payWithAmount: "Pay %AMOUNT%",
  voucherCode: "Voucher Code",
  startLearning: "Start Learning",
  or: "Or",
  buyVoucher: "Buy Voucher",
  forgotVoucher: "Forgot Voucher?",
  sendLink: "Send Link",
  haveVoucherCode: "I have voucher code",
  forgotVoucherMessage: "We will send a login link to your e-mail address",
  voucherCodeInfo:
    'Please note that<ul><li> a voucher code can only be used for one platform, so if you use your voucher for a mobile device app you need a new voucher for online learning.</li><li> a voucher code can only be used by one person from one device (IP address).</li><li>by using our service, you agree to our <a href="https://www.swift.ch/tos/">Terms of Service</a> and <a href="https://www.swift.ch/privacy/">Privacy Policy</a>.</li></ul>',
  buyVoucherPrice: "Buy for $price",
  haveVoucher: "I have voucher code",
  otherProducts: "See other products",
  darkGreenProgressExplanation:
    "$progress questions answered correctly 2 times or more",
  greenProgressExplanation: "$progress questions answered correctly once",
  orangeProgressExplanation: "$progress questions answered incorrectly once",
  redProgressExplanation:
    "$progress questions answered incorrectly 2 times or more",
  grayProgressExplanation: "$progress questions not yet answered",
  emptyProgress:
    "Your progress will appear here. Try clicking on one of the categories below to start learning.",
  startNewTest: "Start New Test",
  noUnfinishedTest: "Take a test to see how ready you are for the real exam!",
  continueTest: "Continue",
  unfinishedTest:
    "You haven't finished a test. Click Continue button to continue the test.",
  testsHistory: "Previous Tests",
  percent: "Percentage",
  score: "Score",
  testDate: "Date",
  learn: "Learn",
  test: "Test",
  yourProgress: "Your Progress",
  addToFavourite: "Add to Favourite",
  report: "Report",
  back: "Back",
  finish: "Finish",
  check: "Check",
  next: "Next",
  explanation: "Explanation",
  correctAnswer: "Correct answer",
  yourAnswer: "Your answer",
  rightAnswer: "Right answer!",
  wrongAnswer: "Wrong answer",
  allCategories: "All Categories",
  home: "Dashboard",
  youPassed: "You Passed 👍",
  youFailed: "You Failed 😢",
  percentage: "Percentage",
  duration: "Duration",
  closeTestResult: "See tests history",
  testQuestions: "Test Questions",
  testResult: "Test Result",
  allQuestions: "All questions",
  successQuestions: "Correct",
  failedQuestions: "Wrong",
  zeroCorrect: "You did't answer any questions correctly 😞",
  zeroMistake: "You answered all questions correctly! 👍",
  search: "Search",
  submit: "Search",
  noResult: "No result",
  result: "Result",
  searchPlaceholder: "Search for questions ...",
  backToSearch: "Back",
  voucherInvalid: "Voucher has been used or invalid",
  "voucherInvalid-1": "Invalid voucher 😵",
  "use-new-elearning":
    "We have a new e-learning platform. Please click the button below to start learning on the new platform.",

  "voucherInvalid-2":
    "Please check your voucher and e-mail address. Make sure you  enter the code correctly. 👀",
  "voucherInvalid-3": "The voucher has expired. Please purchase a new one. 😉",
  "voucherInvalid-4": "Invalid voucher 😱",
  voucherInvalidZeroOMessage:
    'Try changing "0" (zero) with capital "O" or the other way around',
  categories: "Categories",
  assistedLearning: "Assisted Learning",
  explanationWillShowHere: "Explanation will appear here",
  quitTest: "Quit Test",
  reportProblemSubject:
    "Problem report for question:%questionCode% language:%language% country:%country% type:%type% version:%version%",
  reportProblemBody:
    "Problem report for question %questionCode%. Please describe the problem below:",
  pleaseWait: "Please wait ...",
  processingPayment: "Processing payment. Please wait ...",
  logout: "Logout",
  error: "Error",
  voucherForgotMessage:
    "If we have your e-mail address in our system, you will receive an email from us. Please log in using the link we just sent to your e-mail address",
  verifyMessage:
    "You are logging in from different device. Please login using the link we have sent to your e-mail adress.",
  verifyError:
    "The login link you used have expired. Please log in using a new link we just sent to your e-mail address.",
  verifyNotExist: "The login link you used is not valid.",
  pleaseReload: "Cannot load the page. Please reload the page.",
  errorPoints: "Error points",
  points: "Points",
  buyNow: "BUY NOW",
  payOnce: "Pay Once. Valid for 1 Year.",
  productsTitle: "Choose what you want to learn",
  products: "Products",
  userDisabled:
    "Your account has been deactivated. Please contact support@itheorie.ch for information.",
  ipAdressesLimitReached:
    "The voucher code has been used by too many different devices / IP addresses. Please log in using the device you used initially.",
  noExplanation: "No explanation",
  Cancel: "Cancel",
  scanQRCode: "Scan QR Code",
  NotAllowedError:
    "You need to allow itheorie.ch to access your camera to scan the QR code.",
  lightTheme: "Light theme",
  darkTheme: "Dark theme",
  unsupportedLanguage: "This product is not available in English.",
  coaching: "Coaching",
  you: "You",
  student: "Student",
  teacher: "Teacher",
  askPlaceholder: "Ask a question or an advice from the $partner.",
  sending: "Sending",
  send: "Send",
  studentPassedTest: "Test passed.",
  studentFailedTest: "Test failed.",
  connectedTo: "You are connected $partner.",
  notConnectedToTeacher:
    "You are not connected to any teachers. Find teacher to help with your study below.",
  searchTeacherPlaceholder: "Search teacher by name or place",
  foundNumberOfTeachers: "Number of teachers found: $number",
  requestToConnectToTeacher: "Request to connect",
  emptyTeachersSearchResult: "Cannot find any teachers.",
  connectRequested:
    "You have requested to connect to $teacher. When $teacher accepted your request, you will be able to communicate here.",
  studentDeactivated: "$teacher is currently unavailable.",
  provideName: "Please provide your name",
  sendingRequest: "Sending request. Please wait.",
  selectProductOrSet: "Here you can change the question set"
};
