// @flow

import React, { Fragment } from "react";
import styled from "styled-components";
import { withRouter } from "react-router";
import { Footer } from "../text";
import Button, { Link } from "../button";
import Space from "../spacing";

export type MessagesType = {
  title: string,
  emailAddress: string,
  voucherCode: string,
  startLearning: string,
  or: string,
  buyVoucher: string,
  forgotVoucher: string,
  voucherCodeInfo: string,
  sendLink: string,
  haveVoucherCode: string,
  forgotVoucherMessage: string,
  scanQRCode: string
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  margin-bottom: 16px;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: normal;
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
  outline: none;
  color: #212121;
`;
const ForgotScanContainer = styled(Footer)`
  a ~ a::before {
    content: "";
    padding: 0 10px;
  }
`;

type LoginFormProps = {
  username?: string,
  password?: string,
  csrfToken?: string,
  disableSubmitButton?: boolean,
  onChange: (fieldName: string, value: string) => void,
  locale?: string,
  isForgotVoucher?: boolean,
  onClickForgotVoucher: () => void,
  messages: MessagesType,
  history: any,
  match: any,
  onSubmit: (values: {
    username?: string,
    password?: string,
    isForgotVoucher: boolean
  }) => void,
  isSubmittingForm: boolean,
  hideBuyButton: boolean,
  onClickScan: () => void
};
export const LoginForm = ({
  username,
  password,
  disableSubmitButton = true,
  onChange,
  isForgotVoucher = false,
  messages,
  onClickForgotVoucher,
  history,
  match,
  onSubmit,
  isSubmittingForm,
  hideBuyButton,
  onClickScan
}: LoginFormProps) => (
  <Form
    autoComplete="on"
    method="POST"
    onSubmit={e => {
      e.preventDefault();
      onSubmit({
        username,
        password,
        isForgotVoucher
      });
    }}>
    <Footer>{messages.emailAddress}</Footer>
    <Input
      type="email"
      name="username"
      placeholder="your@email.com"
      autoComplete="on"
      value={username}
      onChange={e => onChange("username", e.target.value)}
      onBlur={e => onChange("username", e.target.value)}
    />
    {isForgotVoucher ? null : (
      <Fragment>
        <Footer>{messages.voucherCode}</Footer>
        <Input
          autoComplete="on"
          name="password"
          placeholder="XXXX-XXXX-XXXX"
          value={password}
          onChange={e => onChange("password", e.target.value)}
        />
      </Fragment>
    )}
    {isForgotVoucher && (
      <Fragment>
        <Space v={10} />
        <Footer
          align="center"
          dangerouslySetInnerHTML={{ __html: messages.forgotVoucherMessage }}
        />
        <Space v={10} />
      </Fragment>
    )}
    <Button
      primary
      disabled={disableSubmitButton}
      onClick={e => {
        e.preventDefault();
        onSubmit({
          username,
          password,
          isForgotVoucher
        });
      }}>
      {isForgotVoucher ? messages.sendLink : messages.startLearning}
    </Button>
    {isForgotVoucher || isSubmittingForm || hideBuyButton ? (
      <Space v={10} />
    ) : (
      <Fragment></Fragment>
    )}

    {!isSubmittingForm && !hideBuyButton && (
      <ForgotScanContainer align="center">
        <Link
          href="#"
          onClick={e => {
            e.preventDefault();
            onClickForgotVoucher();
          }}>
          {isForgotVoucher ? messages.haveVoucherCode : messages.forgotVoucher}
        </Link>
      </ForgotScanContainer>
    )}
  </Form>
);

export default withRouter(LoginForm);
